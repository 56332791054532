






















































import { Component, Vue } from 'vue-property-decorator';
import { createLogger, Logger } from '../services/logger.service';
import { AuthService } from '../services/auth.service';
import { SSOEnabledResponse } from '@/models/APILoginResponse';
import HomerLogo from '../components/HomerLogo.vue';

@Component({
    components: {
        HomerLogo,
    },
})
export default class Login extends Vue {

    private logger: Logger = createLogger(Login.name);

    name: string = '';
    password: string = '';

    loginErrMsg = false;

    errorBoxClasses = {
        'toast': true,
        'toast-error': true,
        'animated': true,
        'fadeIn': true,
        'fadeOut': false,
    };

    hideTimeoutHandle!: NodeJS.Timeout;

    ssoEnabled: SSOEnabledResponse | null = null;

    ssoErrorMessage: string | null = null;

    mounted() {
        (this.$refs.usernameInput as HTMLInputElement).focus();

        AuthService.getInstance().isOAuth2Supported().then((e) => {
            this.ssoEnabled = e;
        }).catch(err => {
            this.ssoEnabled = null;
        });
        
        if (this.$route.query.error === '1' && this.$route.query.errmsg) {
            this.ssoErrorMessage = this.$route.query.errmsg as string;
        }

        console.log();
    }

    async onLogin(evt: Event) {
        evt && evt.preventDefault();
        this.logger.info("onLogin: ", this.name);

        const success = await AuthService.getInstance().login(this.name, this.password);

        if (!success) {
            this.loginErrMsg = true;
            this.hideTimeoutHandle = setTimeout(this.onCloseMessage, 8400);
        } else {
            this.$router.push('/');
        }
    }

    onSSOLogin() {
        if (this.ssoEnabled) {
            window.location.href = this.ssoEnabled.authRedirectUrl;
        }
    }

    onCloseMessage() {
        if (this.hideTimeoutHandle) {
            clearTimeout(this.hideTimeoutHandle);
        }

        this.errorBoxClasses.fadeIn = false;
        this.errorBoxClasses.fadeOut = true;
        setTimeout(() => {
            this.errorBoxClasses.fadeIn = true;
            this.errorBoxClasses.fadeOut = false;
            this.loginErrMsg = false;
        }, 1000);
    }

}
