

























import { Component, Vue, Prop } from 'vue-property-decorator';
import AbstractDialog from './abstract-dialog';

@Component({
    name: 'confirm-delete-dialog',
})
export default class ConfirmDeleteDialog extends AbstractDialog {

    @Prop() message!: string;

    onConfirm() {
        this.$emit('confirm');
        this.close();
    }

}

