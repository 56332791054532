








































































import { Component, Vue } from 'vue-property-decorator';
import AboutDialog from '../components/AboutDialog.vue';
import UserChangePasswordDialog from '../components/UserChangePasswordDialog.vue';
import EmployeeStateChangeDialog from '../components/EmployeeStateChangeDialog.vue';
import HomerLogo from '../components/HomerLogo.vue';
import Login from '../views/Login.vue'
import { AuthService } from '../services/auth.service';
import { EmployeeStateTransision, LAST_STATE_TRANSISIONS_KEY } from '../definitions';
import { DataService } from '../services/data.service';
import { Employee } from '../models/employee';
import { EventService, TOPIC_EMPLOYEE_STATE_CHANGE, Unsubscribe } from '../services/event.service';

@Component({
name: 'Home',
  components: {
    Login,
    AboutDialog,
    UserChangePasswordDialog,
    HomerLogo,
    EmployeeStateChangeDialog
  },
})
export default class Home extends Vue {

    private authService: AuthService = AuthService.getInstance();
    private dataService: DataService = DataService.getInstance();

    currentUserId!: string;
    isKioskView!: boolean;
    isAboutDialogShown: boolean = false;
    isPasswordChangeDialogShown: boolean = false;
    userName!: string;

    isSSO: boolean = false;

    hasQuickStateToggle: boolean = false;
    quickStateToggleEmployee: Employee | null = null;

    isStateChangeDialogShown: boolean = false;

    subscription: Unsubscribe | null = null;

    created() {
        this.currentUserId = this.authService.userId;
        this.userName = this.authService.getUserName();
        this.isKioskView = this.authService.isKioskView();

        const isSSO = this.authService.getTokenValue<boolean | number>('isSSO');
        this.isSSO = (isSSO === true || isSSO === 1);

        this.subscription = EventService.subscribe<string | null>(TOPIC_EMPLOYEE_STATE_CHANGE, _ => {
            this.updateQuickStateToggleState();
        });
    }

    beforeDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async onLogout() {
        if ((await this.authService.logout())) {
            this.$router.push({ name: 'Login' });
        }
    }

    hasNoBackgroundImage() {
        return !this.quickStateToggleEmployee || !this.quickStateToggleEmployee.avatarUrl;
    }

    getAvatarBackgroundImageStyleForQuickToggle() {
        if (this.hasNoBackgroundImage()) {
            return {};
        }

        return { backgroundImage: 'url(' + (this.quickStateToggleEmployee?.avatarUrl || '') + ')' };
    }

    getAvatarClassesForToggle() {
        const item = this.quickStateToggleEmployee;
        if (!item) {
            return {};
        }

        const classes = {
            'active-ho': item.state.state === 'HOMEOFFICE', 
            'active-pause': item.state.state === 'PAUSE',
            'active-cust': item.state.state === 'CUSTOMER',
        };
        return classes;
    }    

    async updateQuickStateToggleState() {
        const trans = this.authService.getProperty<EmployeeStateTransision[]>(LAST_STATE_TRANSISIONS_KEY, []);
        const uid = this.getCurrentUserForEmployeeStateTransisions(trans);
        this.hasQuickStateToggle = uid !== null;

        if (uid !== null) {
            this.quickStateToggleEmployee = await this.dataService.getEmployeeById(uid);
        }
    }


    getCurrentUserForEmployeeStateTransisions(trans: EmployeeStateTransision[]): string | null {
        if (!trans || !Array.isArray(trans) || trans.length < 1) {
            return null;
        }

        // Count the results
        const probMap: { [key: string]: number; } = {};
        trans.forEach((v: EmployeeStateTransision) => {
            if ((typeof probMap[v.employeeId]) === 'undefined') {
                probMap[v.employeeId] = 0;
            }
            probMap[v.employeeId]++;
        });

        // Get the highest match
        let maxCount = -1;
        let userId = null;
        for (let key in probMap) {
            if (probMap[key] > maxCount) {
                maxCount = probMap[key];
                userId = key;
            }
        }

        return userId;
    }   

}

