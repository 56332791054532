
















import { Component, Vue } from 'vue-property-decorator';
import EmployeeListSimple from '../components/EmployeeListSimple.vue';
import EmployeeListGrouped from '../components/EmployeeListGrouped.vue';
import { DataService } from '../services/data.service';
import { Employee } from '../models/employee';
import EmployeeEditDialog from '../components/EmployeeEditDialog.vue';
import { createLogger, Logger } from '../services/logger.service';
import { AuthService } from '../services/auth.service';

@Component({
    name: 'AdminView',
})
export default class AdminView extends Vue {

}

