

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Employee } from '../models/employee';
import EmployeeEditDialog from './EmployeeEditDialog.vue';
import EmployeeStateChangeDialog from './EmployeeStateChangeDialog.vue';
import ConfirmDeleteDialog from './ConfirmDeleteDialog.vue';
import { DataService } from '../services/data.service';
import { createLogger, Logger } from '../services/logger.service';

@Component({
    name: 'employee-item',
    components: {
      'employee-edit-dialog': EmployeeEditDialog,
      'employee-state-change-dialog': EmployeeStateChangeDialog,
      'confirm-delete-dialog': ConfirmDeleteDialog,
    }
})
export default class EmployeeItem extends Vue {

    private logger: Logger = createLogger(EmployeeItem.name);

    private dataService: DataService = DataService.getInstance();

    @Prop() item!: Employee;

    @Prop() isShowAll!: boolean;

    isEditDialogShown: boolean = false;
    isStateChangeDialogShown: boolean = false;
    isConfirmDeleteDialogShown: boolean = false;
        
    getAvatarBackgroundImageStyle(employee: Employee) {
        if (!employee || !employee.avatarUrl) {
            return {};
        }

        return { backgroundImage: 'url(' + employee.avatarUrl + ')' };
    }
        
    getAvatarClasses(item: Employee) {
        const classes = {
            'avatar-circle-container': true, 
            'active-ho': item.state.state === 'HOMEOFFICE' || item.state.state === 'OFFICE', 
            'active-pause': item.state.state === 'PAUSE',
            'active-cust': item.state.state === 'CUSTOMER',
        };
        return classes;
    }
        
    async onDeleteEmployee(evt: Event, employee: Employee, execute: boolean = false) {
        evt && evt.preventDefault();
        this.logger.info(`onDeleteEmployee:`, employee, execute);

        // Actually perform the action
        if (execute === true) {
            const ok = await this.dataService.deleteEmployee(employee.id);
            this.logger.info(`Delete successfull:`, ok);
            return;
        }

        this.isConfirmDeleteDialogShown = true;
    }

}

