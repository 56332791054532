


















import { Component, Vue, Prop } from 'vue-property-decorator';
import EmployeeItem from './EmployeeItem.vue';

@Component({
    name: 'employee-list-grouped',
    components: {
      EmployeeItem,
    }
})
export default class EmployeeListGrouped extends Vue {

    @Prop() items!: any;

    @Prop() isShowAll!: boolean;

    getStates(skipOptional = false): any[] {
        return ([
            {
                key: 'HOMEOFFICE',
                name: 'Im Homeoffice',
                icon: 'home_work',
            },
            {
                key: 'OFFICE',
                name: 'Im Büro',
                icon: 'business',
            },
            {
                key: 'CUSTOMER',
                name: 'Kundentermin',
                icon: 'calendar_today',
            },
            {
                key: 'PAUSE',
                name: 'Gerade in Pause',
                icon: 'free_breakfast',
            },
            {
                key: 'OFFLINE',
                name: 'Nicht anwesend',
                optional: true,
                icon: 'exit_to_app',
            }
        ] as any[]).filter((opt: any) => (skipOptional ? (skipOptional && opt.optional !== true) : true));
    }

    filterItemsByState(stateKey: string): any[] {
        return this.items.filter((item: any) => {
            return item.state && item.state.state === stateKey;
        });
    }

    getItemCountByState(stateKey: string): number {
        return this.filterItemsByState(stateKey).length;
    }

}

