






import { Component, Vue, Prop } from 'vue-property-decorator';
import EmployeeItem from './EmployeeItem.vue';

@Component({
    name: 'employee-list-simple',
    components: {
      EmployeeItem,
    }
})
export default class EmployeeListSimple extends Vue {

    @Prop() items!: any;

    @Prop() isShowAll!: boolean;

}

