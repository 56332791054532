





























import { Component, Vue, Prop } from 'vue-property-decorator';
import AbstractDialog from './abstract-dialog';
import { DataService } from '../services/data.service';

@Component({
    name: 'about-dialog',
})
export default class AboutDialog extends AbstractDialog {

  changelog: string = '';
  versionString: string = '';

  private dataService: DataService = DataService.getInstance();

  async mounted() {
    const [ver, changelog] = await Promise.all([
      this.dataService.getVersion(),
      this.dataService.getChangelog(),
    ]);

    this.versionString = ver;
    this.changelog = changelog;
  }

}

