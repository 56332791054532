












































import { Component, Vue } from 'vue-property-decorator';
import EmployeeListSimple from '../components/EmployeeListSimple.vue';
import EmployeeListGrouped from '../components/EmployeeListGrouped.vue';
import { DataService } from '../services/data.service';
import { Employee } from '../models/employee';
import EmployeeEditDialog from '../components/EmployeeEditDialog.vue';
import { createLogger, Logger } from '../services/logger.service';
import { AuthService } from '../services/auth.service';

@Component({
    name: 'EmplyoeeView',
    components: {
        EmployeeListSimple,
        EmployeeListGrouped,
        EmployeeEditDialog,
    },
})
export default class EmplyoeeView extends Vue {
    
    private logger: Logger = createLogger(EmplyoeeView.name);

    private dataService: DataService = DataService.getInstance();

    private authService: AuthService = AuthService.getInstance();

    private eventSource!: EventSource;

    items: Employee[] = [];

    isShowAll = true;
    isGroupedView = false;
    isEditDialogShown: boolean = false;

    async loadData() {
        this.items = await this.dataService.getEmployees(this.isShowAll);
    }

    get isKioskView(): boolean {
        return this.authService.isKioskView();
    }

    onChangeViewMode() {
        this.isShowAll = !this.isShowAll;
        this.loadData();
        this.authService.setProperty("employeeView.isShowAll", this.isShowAll);
    }

    onChangeGroupView(isGrouped: boolean) {
        this.isGroupedView = isGrouped;
        this.authService.setProperty("employeeView.isGroupedView", this.isGroupedView);
    }

    mounted() {
        this.logger.info("Component mounted");
        this.loadData();

        this.isShowAll = this.authService.getProperty("employeeView.isShowAll", false);
        this.isGroupedView = this.authService.getProperty("employeeView.isGroupedView", true);

        this.eventSource = this.dataService.subscribeEvents((_: string, p: any) => {
            this.logger.info(`Employee state changed:`, p);
            this.loadData();
        }, ['EmployeeStateChange', 'EmployeeCreated', 'EmployeeDeleted', 'EmployeeUpdated', 'OnConnect']);
    }

    beforeDestroy() {
        this.logger.info(`Going to destroy component ...`);
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

}

