














import { Component, Vue, Prop } from 'vue-property-decorator';
import AbstractDialog from './abstract-dialog';
import { DataService } from '../services/data.service';

@Component({
    name: 'homer-logo',
})
export default class HomerLogo extends AbstractDialog {

}

