







import { Component, Vue } from "vue-property-decorator";
import Toaster from "./components/Toaster.vue";

@Component({
  components: {
    Toaster,
  },
})
export default class App extends Vue {
  mounted() {
    setTimeout(() => {
      const el = document.getElementById("intial-loader");
      if (el) {
        el.className = "animate__animated animate__fadeOut";
        setTimeout(() => {
          el.parentNode && el.parentNode.removeChild(el);
        }, 750);
      }
    }, 750);
  }
}
