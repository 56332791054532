










































import { Component, Vue, Prop } from 'vue-property-decorator';
import AbstractDialog from './abstract-dialog';
import { DataService } from '../services/data.service';
import owasp, { test } from "owasp-password-strength-test";
import { ToastService } from '../services/toast.service';

@Component({
    name: 'user-change-password-dialog',
})
export default class UserChangePasswordDialog extends AbstractDialog {

    private dataService: DataService = DataService.getInstance();

    private toastService: ToastService = ToastService.getInstance();

    @Prop() userId!: string;

    currentPassword: string = '';
    newPassword: string = '';
    newPasswordRpt: string = '';
    isLoading = false;

    isValid() {
        return this.newPassword === this.newPasswordRpt 
            && !!this.newPassword 
            && this.newPassword.length > 0;
    }

    getStylesForBar(pwd: string) {
        // Test the password
        const r = owasp.test(pwd);
        let q = Math.round((r.passedTests.length / (r.passedTests.length + r.failedTests.length)*100));
        if (pwd.length > 1 && q < 10) {
            q = 10;
        } else if (!pwd) {
            q = 0;
        }

        return {
            'backgroundColor': (q < 25 ? '#e85600' : (q < 75 ? '#ffb700' : '#32b643')),
            'width': `${q}%`
        };
    }

    async onChangePassword() {
        this.isLoading = true;

        const ok = await this.dataService.changePassword(
            this.userId, this.currentPassword, this.newPassword
        );

        if (ok) {
            this.close();
        } else {
            this.toastService.alert(
                `Das Passwort konnte nicht geändert werden! Prüfen Sie auf Tippfehler und versuchen Sie es erneut!`
            );
        }

        this.isLoading = false;
    }

}

