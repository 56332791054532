





































import { Component, Vue, Prop } from 'vue-property-decorator';
import EmployeeItem from './EmployeeItem.vue';
import { Employee } from '../models/employee';
import AbstractDialog from './abstract-dialog';
import { DataService } from '../services/data.service';
import { createLogger, Logger } from '../services/logger.service';

@Component({
    name: 'employee-state-change-dialog',
})
export default class EmployeeStateChangeDialog extends AbstractDialog {

    private loger: Logger = createLogger(EmployeeStateChangeDialog.name);
    
    private dataService: DataService = DataService.getInstance();

    @Prop() employee!: Employee;

    message: string = '';

    states: Array<any> = [];

    async mounted() {
        this.states = await this.dataService.getAvailableStates();
        console.log(this.states);
    }

    async onTriggerStateChange(newState: string) {
        this.loger.info(`onTriggerStateChange:`, newState);

        try {
            await this.dataService.setEmployeeState(this.employee.id, newState, this.message);
            this.close();
        } catch (ex) {
            this.loger.error(`Cannot set employee state:`, newState, this.message, ex);
        }
    }

}
