























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '../models/User';
import { DataService } from '../services/data.service';
import UserChangePasswordDialog from '../components/UserChangePasswordDialog.vue';
import { createLogger, Logger } from '../services/logger.service';
const urlJoin = require('url-join');


@Component({
    name: 'user-admin',
    components: {
        UserChangePasswordDialog,
    }
})
export default class UserAdmin extends Vue {

    private logger: Logger = createLogger(UserAdmin.name);

    private dataService: DataService = DataService.getInstance();

    users: User[] = [];

    roles = [
        {
            value: '*',
            name: 'Administrator'
        },
        {
            value: 'default',
            name: 'Nutzer'
        },
        {
            value: 'kiosk',
            name: 'Kiosk-Modus'
        }
    ];

    newUserName = '';
    newUserRoles: string[] = [];
    editModelId = '';
    _editModel = null as any;
    
    mounted() {
        this.logger.info("Starting user admin!");
        this.loadData();
    }

    async loadData() {
        this.users = (await this.dataService.getUsers()).map(u => ({ ...u, _isPasswordChangeDialogShown: false }));
        this.logger.info("Loaded users:", this.users);
    }

    onEditUser(event: Event, user: User) {
        event && event.preventDefault();
        this.editModelId = user.id;
        this._editModel = JSON.parse(JSON.stringify(user));
    }

    async onDeleteUser(event: Event, user: User) {
        event && event.preventDefault();
        await this.dataService.deleteUserById(user.id);
        this.loadData();
    }

    async onCreateUser(event: Event) {
        event && event.preventDefault();

        await this.dataService.createUser({
            name: this.newUserName,
            roles: this.newUserRoles,
        });

        this.onClearForm(event);
        this.loadData();
    }

    onClearForm(event: Event) {
        event && event.preventDefault();
        this.newUserName = '';
        this.newUserRoles = [];
    }
        
    onClearUserEdit(event: Event) {
        event && event.preventDefault();
        if (this.editModelId) {
            this.logger.info(`onClearUserEdit`, this.users);
            const idx = this.users.findIndex((user: User) => (user.id === this.editModelId));
            if (idx > -1) {
                this.users[idx] = this._editModel;
                this._editModel = null;
                this.editModelId = '';
            }
        }
    }

    async onUpdateUser(event: Event, user: User) {
        event && event.preventDefault();
        if (this.editModelId) {
            await this.dataService.updateUserById(user.id, {
                name: user.name,
                roles: user.roles,
                optional: user.optional || {},
            });
            
            this._editModel = null;
            this.editModelId = '';
            // OK
            this.loadData();
        }
    }

    getDirectLoginUrlAbs(user: User) {
        if (!user.directLoginUrl) {
            return null;
        }
        return urlJoin(window.location.origin, user.directLoginUrl);
    }

    onShowCredentials(htmlId: string) {
        const el = document.getElementById(htmlId) as HTMLInputElement;
        if (el.value.indexOf('**') === 0) {
            el.value = el.getAttribute('rel') || '';
        } else {
            el.value = '*****';
        }
    }

}

